import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CategoryList = Loadable(lazy(() => import('./CategoryList')));
const QuestionList = Loadable(lazy(() => import('./QuestionList')));
const OptionList = Loadable(lazy(() => import('./OptionList')));
const AddCategory = Loadable(lazy(() => import('./AddCategory')));

const CategoryRoutes = [
	{
		path: '/dashboard/categorylist',
		element: <CategoryList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/addcategory',
		element: <AddCategory />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/questionlist',
		element: <QuestionList />,
		auth: authRoles.admin,
	},
	{
		path: '/dashboard/optionlist',
		element: <OptionList />,
		auth: authRoles.admin,
	},
];

export default CategoryRoutes;
